import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  },
  beta: {
    color: `#FFA726`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Asma Daher</Text>. 
    </Heading>
    <Heading variant='h1' sx={styles.occupation}></Heading>
    <Heading variant='h3' sx={styles.specialty}>
    A Software Developer | Solution Architect | Tech Entrepreneur <br/>
    {/* Who's into Building Digital Products */}
    </Heading>
    <Heading variant='h3' sx={styles.beta}>
    Beta Version
    </Heading>

    <Box variant='buttons.group'>
      <Button as={Link} to='https://twitter.com/AsmaSDaher'>
        Twitter
      </Button>
      <Button variant='white' as={Link} to='/about'>
        About Me
      </Button>
    </Box>
  </>
)
